import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbUrlTravelPlanning, dbUrlWTDlist } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../../SEO";
import {useParams, Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const TravelPlanning = () => {
    const [locations, setLocations] = useState([])
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)

    const params = useParams();
    
    useEffect(() => {
        const fetchLocations = () => {
          axios.get(dbUrlTravelPlanning + params.slug)
          .then(response => {
            setLoading2(false)
            setLocations(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        const fetchList = () => {
            axios.get(dbUrlWTDlist + params.slug)
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }
        fetchList()
        fetchLocations()
      }, [params.slug])
  
    if (error) return `Error: ${error.message|errorList.message}`;

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };
    

    if (loading || loading2) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                
                                    <h1>Oops! You seem to be lost.</h1>
                                    <p>Here are some helpful links:</p>

                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                        <li><Link to='/allbooks'>All Books</Link></li>
                                        <li><Link to='/allquotes'>All Quotes</Link></li>
                                    </ul>
                                    
                                
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const {name, description, pic_link, page} = list[0];
    let sortedLocations
    //SORTERING

    sortedLocations = locations.sort((a, b) => {
            return a.group_sort - b.group_sort;
        });

    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>
            
                <div className="row mb-3 gx-5">
                    <div>
                        <h1>{value.name}</h1>
                    </div>

                    <div className="col-sm-4 Description">

                        <div className="mt-2"><img src={value.pic_link} alt="alt" className="img-fluid" /> 
                        </div>
                        
                    </div>
                    <div className="col-sm-4 Description">
                        <div className="mt-2"><img src={value.map} alt="alt" className="img-fluid" /> 
                        </div>
                    </div>
                    <div className="col-sm-4">

                        <div className="p-3 mt-2 border bg-light Description2">
                            {value.description} 
                        </div>
                        <div className="p-3 mt-2 border bg-light Description">
                            {value.map_comment} 
                        </div>
                        <div className="mt-4">
                            <button className="btn btn-primary btn-sm" type="button" data-bs-toggle="collapse" 
                            data-bs-target="#collapsePronunciations" aria-expanded="false" aria-controls="collapsePronunciations">
                                Pronunciation
                            </button>
                            <div className="collapse mt-2" id="collapsePronunciations">
                                <div className="card card-body">
                                     <ReactMarkdown>
                                        {value.pronunciations}                   
                                    </ReactMarkdown>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
                   
            </React.Fragment>
            )
    })

    const LocationItems = sortedLocations.map(value => {

        return (

            <React.Fragment key={value.id}>
                <div className="row pt-3 pb-3 border-bottom border-top border-info">

                    <div className="row DestinationTitle">
                        <div className="col"> 
                            <div>&nbsp;<b>{value.location}, {value.sub_region}, {value.region} - {value.country}</b></div>
                        </div>
                    </div>

                    {/* kolonne 1 */}
                    <div className="col-sm-4 text-center">
                        <div className="mt-2"><img src={value.picture} alt="alt" className="img-fluid" /> 
                        </div>
                    </div>

                    {/* kolonne 2 */}
                    <div className="col-sm-8 mt-2">
                        {
                            (() => {
                            if (value.main_comment) {
                            return <div className="card bg-light mb-3 px-2 py-1">
                                        <div className="quote12">{value.main_comment}</div> 
                                        <div className="blockquote-footer mt-1 quote12"><i>{value.main_comment_source}</i></div>
                                    </div>
                            }
                            return 
                            })()
                        }

                        <div className="Description2">
                            <ReadMoreAndLess
                                charLimit={1200}
                                readMoreText={" Read more ▼"}
                                readLessText={" Read less ▲"}
                                readMoreClassName="read-more-less--more"
                                readLessClassName="read-more-less--less"
                                >
                                {value.description}
                            </ReadMoreAndLess> 
                        </div>
                        {
                            (() => {
                            if (value.things_to_do) {
                            return <div className="mt-2 pt-2 border-top Text13">
                                        <ReactMarkdown>
                                            {value.things_to_do}               
                                        </ReactMarkdown>
                                    </div>
                            }
                            return 
                            })()
                        }

                        
                        
                        
                    </div>

                </div>

                {
                    (() => {
                    if (!value.location_slim) {
                    return <div className="row mt-2">
                                {/* kolonne 1 */}
                                <div className="col-sm-4 p-3 border-end">
                                    <div className="TitleSightsCat mb-3 p-1"><b>{value.cat1}</b></div>
                                    <ReactMarkdown>
                                        {value.cat1_txt}               
                                    </ReactMarkdown>
                                </div>

                                {/* kolonne 2 */}
                                {
                                    (() => {
                                    if (value.cat2) {
                                    return <div className="col-sm-4 p-3 border-end">
                                                    <div className="TitleSightsCat mb-3 p-1"><b>{value.cat2}</b></div>
                                                    <ReactMarkdown>
                                                        {value.cat2_txt}                       
                                                    </ReactMarkdown>
                                                </div>
                                        
                                    }
                                    return 
                                    })()
                                }
                    
                                {/* kolonne 3 */}
                                {
                                    (() => {
                                    if (value.cat3) {
                                    return <div className="col-sm-4 p-3 border-end">
                                                    <div className="TitleSightsCat mb-3 p-1"><b>{value.cat3}</b></div>
                                                    <ReactMarkdown>
                                                        {value.cat3_txt}                       
                                                    </ReactMarkdown>
                                                </div>
                                        
                                    }
                                    return 
                                    })()
                                }

                                </div>
                                        
                    }
                    return 
                    })()
                }

            </React.Fragment>

        )

    })
    
    return (
        <main className="my-1 py-1">
            {locations.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.wisdomthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            {LocationItems}
                        </div>
                    </div>

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>


                </div>
            )}
        </main>
    )
}

export default TravelPlanning