import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbUrlWTDlist, dbUrlTravelPlanning,
    dbUrlTubeOptions, dbURListsRefswtd, customStylesSmall } from '../Constants';
import '../wisdom.css';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import SEO from "../../SEO";
import {useParams, Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { SearchLists, Mobile, SelectProperty } from "../misc/utils";
import Select from 'react-select';
import {DebounceInput} from 'react-debounce-input';

const TravelAttractions = (props) => {
    const [attractions, setAttractions] = useState([])
    const [list, setList] = useState([])
    const [searchString, setSearchString] = useState('')
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [error, setError] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [tagfilter, settagfilter] = useState('all')
    const [optionDistrict, SetOptionDistrict] = useState(null)
    const [references, setReferences] = useState([])
    const [errorReferences, setErrorReferences] = useState(null)
    const [tubeStations, setTubeStations] = useState([])
    const [optionTube, setOptionTube] = useState(null)
    const [errorTube, setErrorTube] = useState(null)
    const [modalImage, setModalImage] = useState(null);

    const params = useParams();

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbUrlWTDlist + params.slug)
            .then(response => {
                setLoading(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchAttrations = () => {
          axios.get(dbUrlTravelPlanning + params.slug)
          .then(response => {
            setLoading2(false)
            setAttractions(response.data)
          }).catch(error => {
            setError(error);
          });
        }

        const fetchReferences = () => {
            axios.get(dbURListsRefswtd + params.slug)
            .then(response => {
                setReferences(response.data)
            }).catch(errorReferences => {
                setErrorReferences(errorReferences);
            });
        }

        const fetchTubeStations = () => {
            axios.get(dbUrlTubeOptions + params.slug)
            .then(response => {
                setTubeStations(response.data)
            }).catch(errorTube => {
                setErrorTube(errorTube);
            });
        }

        fetchList()
        fetchAttrations()
        fetchReferences()
        fetchTubeStations()
      }, [params.slug])
  
    if (error) return `Error tv: ${error.message}`;
    if (errorList) return `Error list: ${errorList.message}`;
    if (errorReferences) return `Error References: ${errorReferences.message}`;
    if (errorTube) return `Error Tube stations: ${errorTube.message}`;

    const isMobile = Mobile();

    if (loading || loading2) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                
                                    <h1>Oops! You seem to be lost.</h1>

                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const {name, description, pic_link, page, use_tags, map_picture, 
        quote_right, bars_pubs, opens_early_tag, group_list} = list[0];

    let filterArray, tagArray, DistrictOptions, sortedReferences, TubeOptions, sortedAttractions;

    //SORTING GroupLists (typisk travel planning pr. dag)
    if (group_list) {
        sortedAttractions = attractions.sort((a, b) => {
            return a.group_sort - b.group_sort;
        });
    }
    else {
        sortedAttractions = attractions;
    }

    sortedReferences = references.sort((a, b) => {
        return b.ref_number - a.ref_number;
    });

    //ToC for Group lists
    //only objects with group_start
    const groupNamesFilter = sortedAttractions.filter(e => e.group_start === true); 
    //only property group_name
    const groupNamesFilterClean = groupNamesFilter.map(item => ({ group_name: item.group_name }));


    //console.log(groupNamesFilterClean);


    DistrictOptions = SelectProperty(attractions, 'district')
    TubeOptions = SelectProperty(tubeStations, 'tube_station')

    const setOptionDistrict = (selectedDistrict) => {
        SetOptionDistrict(selectedDistrict);
    }
    const SelectedTubeStation = (selectedTube) => {
        setOptionTube(selectedTube);
    }

    //tagfilter Group_name
    if (group_list && tagfilter !== 'all') {
        tagArray = sortedAttractions.filter(e => e.group_name === tagfilter);  
    }

    //tagfilter importance
    if (tagfilter === 'red') {
        tagArray = sortedAttractions.filter(e => e.importance === 1);  
        }
    if (tagfilter === 'green') {
        tagArray = sortedAttractions.filter(e => e.importance === 2);  
        } 
    if (tagfilter === 'blue') {
        tagArray = sortedAttractions.filter(e => e.importance === 3);  
        } 
    if (tagfilter === 'all') {
        tagArray = sortedAttractions;  
        }
    //tagfilter category
    if (tagfilter === 'district') {
        tagArray = sortedAttractions.filter(e => e.type === 'District');  
        }
    if (tagfilter === 'art') {
        tagArray = sortedAttractions.filter(e => e.category === 'Art');  
        }
    if (tagfilter === 'pubs') {
        tagArray = sortedAttractions.filter(e => e.category === 'Bars and pubs');  
        }
    if (tagfilter === 'buildings') {
        tagArray = sortedAttractions.filter(e => e.category === 'Buildings');  
        }
    if (tagfilter === 'museums') {
        tagArray = sortedAttractions.filter(e => e.category === 'Museums');  
        }
    if (tagfilter === 'danish_lunch') {
        tagArray = sortedAttractions.filter(e => e.category === 'Danish lunch');  
        }
    if (tagfilter === 'restaurant') {
        tagArray = sortedAttractions.filter(e => e.type === 'Restaurant');  
        }
    
    if (tagfilter === 'opens_early') {
        tagArray = sortedAttractions.filter(e => e.open_early === true);  
        }

        

    //Search
    if (searchString) {
        filterArray = SearchLists(sortedAttractions, 'name', searchString.toLowerCase());
        } 
    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
        };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }

    if (optionDistrict) {
        filterArray = sortedAttractions.filter(e => e.district === optionDistrict.value);
        } 
    
    //her må eg være mere presis
    if (optionTube) {
       filterArray = sortedAttractions.filter(e => e.nearest_tube_station.includes(optionTube.value));
    }

    /* if (optionTube) {
        filterArray = attractions.filter(e => e.id === tubeStations.id);
     }  */

    if (tagArray && !searchString && !optionDistrict && !optionTube) {
        filterArray = tagArray.filter(e => e.remove_from_start_view !== true)    
    }

    const GroupTOC = groupNamesFilterClean.map((value) => (

        <React.Fragment key={value.group_name}>

            {isMobile &&
                <>
                    {tagfilter === value.group_name &&
                        <button type="button" className="btn btn-info me-2 mt-1" 
                        onClick={() => settagfilter(value.group_name)}>
                            <b>{value.group_name}  <span className="badge text-bg-light rounded-pill">
                                {filterArray.length}</span></b></button>
                        }
                    {tagfilter !== value.group_name &&
                        <button type="button" className="btn btn-info SmallButton me-2 mt-1" 
                        onClick={() => settagfilter(value.group_name)}>
                            <b>{value.group_name}</b></button>
                        }
                </>
            }

            {!isMobile &&
                <>

                {/* {!isMobile && (value.group_name === groupNamesFilterClean[3].group_name) &&
                    <div className="mt-2"></div>
                }  */}      
                
                {tagfilter === value.group_name &&
                    <button type="button" className="btn btn-info me-2 mt-1" 
                    onClick={() => settagfilter(value.group_name)}>
                        <b>{value.group_name}  <span className="badge text-bg-light rounded-pill">
                            {filterArray.length}</span></b></button>
                    }
                {tagfilter !== value.group_name &&
                    <button type="button" className="btn btn-info btn-sm me-2 mt-1" 
                    onClick={() => settagfilter(value.group_name)}>
                        <b>{value.group_name}</b></button>
                    }
                </>
            }

        </React.Fragment>
    ))

    const ListHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>

                <div className="ms-2"><h1>{value.name}</h1></div>
            
                <div className="row mb-2 gx-5">
                    
                    {/* kolonne1 */}
                    <div className="col-sm-4 mt-1 Description">

                        <div><img src={value.pic_link} alt="alt" className="img-fluid" /> 
                        </div>
                        <div className="text-center">{value.pic_name}</div>
                        {value.pic2_link &&
                        <div className="mt-2 text-center">
                            <img src={value.pic2_link} alt="alt" className="img-fluid" /> 
                        </div>
                        }
                        {value.pic2_name &&
                            <div className="text-center">{value.pic2_name}</div>
                        }

                        {(value.in_progress || (window.location.hostname === "localhost")) &&
                            <div>
                                
                                <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                
                                
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs mb-2">ALL count: {attractions.length}</div>
                                </div> 
                                <div className="text-center">
                                    <div className="btn btn-info btn-xs">FILTER count: {filterArray.length}</div>
                                </div>
                            </div>
                        }
                        
                    </div>

                    {/* kolonne2 */}
                    <div className="col-sm-5 mt-1">

                        {value.description &&
                            <div className="Date rounded py-1 px-2">
                                <ReactMarkdown>
                                    {value.description}
                                </ReactMarkdown>
                            </div>
                        }
                        
                        {value.comment1 && value.source1 && !quote_right &&
                            <figure className="text-center mt-3 p-2">
                                <blockquote className="blockquote">
                                    <p>{value.comment1}</p>
                                </blockquote>
                                <figcaption className="blockquote-footer mt-2">
                                    {value.source1} <cite title="Source Title">{value.details1}</cite>
                                </figcaption>
                            </figure>
                        }

                        {value.comment1 && !value.source1 && !quote_right &&
                            <figure className="Date rounded p-2 mt-3">
                                <ReactMarkdown>
                                        {value.comment1}               
                                </ReactMarkdown>
                            </figure>
                        }

                        {map_picture &&
                            <div className="mt-3">
                                <h5>Map - {value.name}</h5>
                                <div className="mt-2 text-center" data-bs-toggle="modal" 
                            data-bs-target="#mapModalHeading">
                                    <img src={map_picture} alt="alt" className="img-fluid" /> 
                                </div>
                            </div>
                        }
                        <div className="modal fade" id="mapModalHeading" aria-labelledby="mapModalLabel">
                            <div className="modal-dialog modal-xl">
                                <div className="modal-content">

                                    <div className="modal-header">          
                                        <h2 className="modal-title" id="mapModalLabel">Map - {value.name}</h2>
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>

                                    <div className="modal-body">
                                    
                                        <img src={map_picture}
                                            className="img-fluid rounded" alt=""></img>
                                    </div>
                                    {/* <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        
                        {group_list &&
                            <div>
                                <div className="mx-2 bg-light mb-2 mt-3 py-1 ps-2"><b>Click to use filters:</b> <br /></div>
                                {isMobile &&
                                <>
                                    {tagfilter === 'all' &&
                                        <button type="button" className="btn btn-info me-2 mt-1"
                                        onClick={() => settagfilter('all')}>
                                            <b>All <span className="badge text-bg-light rounded-pill">
                                                {filterArray.length}</span></b></button>
                                    }
                                    {tagfilter !== 'all' &&
                                        <button type="button" className="btn btn-info SmallButton me-2 mt-1"
                                        onClick={() => settagfilter('all')}>
                                            <b>All</b></button>
                                    }
                                </>
                                }
                                {!isMobile &&
                                <>
                                    {tagfilter === 'all' &&
                                        <button type="button" className="btn btn-info me-2 mt-1"
                                        onClick={() => settagfilter('all')}>
                                            <b>All <span className="badge text-bg-light rounded-pill">
                                                {filterArray.length}</span></b></button>
                                    }
                                    {tagfilter !== 'all' &&
                                        <button type="button" className="btn btn-info btn-sm me-2"
                                        onClick={() => settagfilter('all')}>
                                            <b>All</b></button>
                                    }
                                </>
                                }
                                {GroupTOC}   
                            </div>
                        }

                    </div>

                    {/* kolonne3 */}
                    <div className="col-sm-3 mt-1">
                        {!searchString && !optionTube &&
                                    <div className="row my-2">  
                                        <div className="col-8">
                                            <Select
                                                className="select"
                                                value={optionDistrict}
                                                onChange={setOptionDistrict}
                                                options={DistrictOptions}
                                                placeholder='Select a district..'
                                                styles={customStylesSmall}
                                            />
                                        </div>
                                        <div className="col-4 pl-2">
                                            <button  className="btn btn-primary btn-sm"  onClick={() => SetOptionDistrict(null)} >Reset</button>
                                        </div>
                                    </div>
                        }

                        {!searchString && !optionDistrict &&
                                    <div className="row my-2">  
                                        <div className="col-8">
                                            <Select
                                                className="select"
                                                value={optionTube}
                                                onChange={SelectedTubeStation}
                                                options={TubeOptions}
                                                placeholder='Select a station..'
                                                styles={customStylesSmall}
                                            />
                                        </div>
                                        <div className="col-4 pl-2">
                                            <button  className="btn btn-primary btn-sm"  onClick={() => setOptionTube(null)} >Reset</button>
                                        </div>
                                    </div>
                        }  
                        
                        {!optionDistrict && !optionTube &&
                        <div>
                            <div className="row">  
                                <div className="col-8">
                                    <div className="Search"> 
                                        {!bars_pubs &&   
                                            <DebounceInput
                                                //type="number"
                                                minLength={3}
                                                debounceTimeout={1000}
                                                value={searchString}
                                                onChange={handleSearchChange}
                                                placeholder="Search all destinations" 
                                                className="searchSmall"
                                            />
                                        }
                                        {bars_pubs &&   
                                            <DebounceInput
                                                //type="number"
                                                minLength={3}
                                                debounceTimeout={1000}
                                                value={searchString}
                                                onChange={handleSearchChange}
                                                placeholder="Search all pubs" 
                                                className="searchSmall"
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="col-4 pl-0">
                                    <button type="button" className="btn btn-primary btn-sm"  
                                    onClick={() => clearSearchField()}>Reset</button>
                                </div>
                            </div>
                        </div>
                        }

                        {searchString &&
                            <div>
                                <div className='mt-3'>
                                    <div className="btn btn-info btn-xs">total hits: {filterArray.length}</div>
                                </div> 
                            </div>
                        }

                        {value.google_maps &&
                            <div className="mt-2">
                                <div className="rounded p-2">
                                <b>My map @ <a href={value.google_maps} target="_blank" rel="noreferrer">Google maps</a></b>
                                </div>
                            </div>
                        }

                        {value.subway_map && !isMobile &&
                            <div className="mt-3">
                                <div className="rounded quote14">
                                <b>Subway: <a href={value.subway_map} target="_blank" rel="noreferrer">{value.subway_name}</a></b>
                                </div>
                            </div>  
                        }
                        {value.subway_map && isMobile &&
                            <div className="mt-2">
                                <div className="rounded ps-2">
                                <b>Subway: <a href={value.subway_map} target="_blank" rel="noreferrer">{value.subway_name}</a></b>
                                </div>
                            </div>  
                        }

                        {value.source_url &&
                            <div className="mt-4 px-2">
                                <b>List source</b>
                                <div className="Description">
                                    <ul>
                                        <li>
                                            <b><a href={value.source_url}>
                                                {value.source}
                                            </a></b>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        }
                        
                        {value.link1 &&
                            <div>
                                <div className="mt-4"><b>Check out</b></div>
                                <div className="Description">
                                        <ul>
                                            {
                                                (() => {
                                                if (value.link1 && value.link2 && value.link3 && value.link4) {
                                                    return  <div><li><b><a href={value.url1}>
                                                                {value.link1}
                                                            </a></b>
                                                            </li>
                                                            <li><b><a href={value.url2}>
                                                                {value.link2}
                                                            </a></b>
                                                            </li>
                                                            <li><b><a href={value.url3}>
                                                                {value.link3}
                                                            </a></b>
                                                            </li>
                                                            <li><b><a href={value.url4}>
                                                                {value.link4}
                                                            </a></b>
                                                            </li>
                                                            </div>
                                                    }
                                                if (value.link1 && value.link2 && value.link3) {
                                                    return  <div><li><b><a href={value.url1}>
                                                                {value.link1}
                                                            </a></b>
                                                            </li>
                                                            <li><b><a href={value.url2}>
                                                                {value.link2}
                                                            </a></b>
                                                            </li>
                                                            <li><b><a href={value.url3}>
                                                                {value.link3}
                                                            </a></b>
                                                            </li>
                                                            </div>
                                                    }
                                                if (value.link1 && value.link2) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                                return <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                })()
                                            }
                                            
                                        </ul>
                                </div> 
                            </div>
                        }

                        {opens_early_tag &&
                            <div className="mt-4">
                                    <div className="mx-4 bg-light py-1 ps-2"><b>Click to use filter:</b> <br />
                                        {tagfilter === 'all' &&
                                        <button type="button" className="btn btn-info btn-sm mx-2"
                                        onClick={() => settagfilter('all')}>
                                            <b>All <span className="badge text-bg-light rounded-pill">
                                                {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'all' &&
                                        <button type="button" className="btn btn-info SmallButton mx-2"
                                        onClick={() => settagfilter('all')}>
                                            <b>All</b></button>
                                        }

                                        {tagfilter === 'opens_early' &&
                                        <button type="button" className="btn btn-success SmallButton btn-sm me-1"
                                        onClick={() => settagfilter('opens_early')}>
                                            <b>Opens early  <span className="badge text-bg-light rounded-pill">
                                                {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'opens_early' &&
                                        <button type="button" className="btn btn-success SmallButton me-1"
                                        onClick={() => settagfilter('opens_early')}>
                                            <b>Opens early</b></button>
                                        }

                                    </div>
                            </div>

                        }

                        {use_tags &&
                            <div>
                                {isMobile &&
                                <div className="mt-4">
                                    <div className="mx-4 bg-light py-1 ps-2"><b>Click to use filter:</b></div>
                                    <div className="Text18 mb-1 mt-1 text-center">
                                        {tagfilter === 'all' &&
                                        <button type="button" className="btn btn-info btn-sm me-2"
                                        onClick={() => settagfilter('all')}>
                                            <b>All <span className="badge text-bg-light rounded-pill">
                                                {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'all' &&
                                        <button type="button" className="btn btn-info SmallButton me-2"
                                        onClick={() => settagfilter('all')}>
                                            <b>All</b></button>
                                        }
                                        {tagfilter === 'red' &&
                                        <button type="button" className="btn btn-danger btn-sm me-2"
                                        onClick={() => settagfilter('red')}>
                                            <b>Must See <span className="badge text-bg-light rounded-pill">
                                                {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'red' &&
                                        <button type="button" className="btn btn-danger SmallButton me-2"
                                        onClick={() => settagfilter('red')}>
                                            <b>Must See</b></button>
                                        }
                                        {tagfilter === 'green' &&
                                        <button type="button" className="btn btn-success btn-sm me-2"
                                        onClick={() => settagfilter('green')}>
                                            <b>Try Hard to See  <span className="badge text-bg-light rounded-pill">
                                                {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'green' &&
                                        <button type="button" className="btn btn-success SmallButton me-2"
                                        onClick={() => settagfilter('green')}>
                                            <b>Try Hard to See</b></button>
                                        }
                                        {tagfilter === 'blue' &&
                                        <button type="button" className="btn btn-primary btn-sm"
                                        onClick={() => settagfilter('blue')}>
                                            <b>Worthwhile  <span className="badge text-bg-light rounded-pill">
                                                {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'blue' &&
                                        <button type="button" className="btn btn-primary SmallButton"
                                        onClick={() => settagfilter('blue')}>
                                            <b>Worthwhile</b></button>
                                        }
                                    </div> 

                                    <hr className="bg-danger border-2 border-secondary mx-auto" style={{ width: '200px' }} />

                                    <div className="mb-1 mt-1 text-center">
                                        {params.slug === 'Copenhagen-things-to-do' &&
                                            <>
                                                {tagfilter === 'danish_lunch' &&
                                                <button type="button" className="btn btn-danger SmallButton me-1"
                                                onClick={() => settagfilter('danish_lunch')}>
                                                    <b>Classic Danish lunch  <span className="badge text-bg-light rounded-pill">
                                                        {filterArray.length}</span></b></button>
                                                }
                                                {tagfilter !== 'danish_lunch' &&
                                                <button type="button" className="btn btn-danger SmallButton me-1"
                                                onClick={() => settagfilter('danish_lunch')}>
                                                    <b>Classic Danish lunch</b></button>
                                                }
                                                {tagfilter === 'restaurant' &&
                                                <button type="button" className="btn btn-secondary SmallButton me-1"
                                                onClick={() => settagfilter('restaurant')}>
                                                    <b>Restaurants<span className="badge text-bg-light rounded-pill">
                                                        {filterArray.length}</span></b></button>
                                                }
                                                {tagfilter !== 'restaurant' &&
                                                <button type="button" className="btn btn-secondary SmallButton me-1"
                                                onClick={() => settagfilter('restaurant')}>
                                                    <b>Restaurants</b></button>
                                                }
                                            </>
                                        }
                                        {params.slug !== 'copenhagen' &&
                                        <>
                                            {tagfilter === 'district' &&
                                            <button type="button" className="btn btn-secondary SmallButton btn-sm me-1"
                                            onClick={() => settagfilter('district')}>
                                                <b>District  <span className="badge text-bg-light rounded-pill">
                                                    {filterArray.length}</span></b></button>
                                            }
                                            {tagfilter !== 'district' &&
                                            <button type="button" className="btn btn-secondary SmallButton me-1"
                                            onClick={() => settagfilter('district')}>
                                                <b>District</b></button>
                                            }
                                            {tagfilter === 'art' &&
                                            <button type="button" className="btn btn-secondary SmallButton btn-sm me-1"
                                            onClick={() => settagfilter('art')}>
                                                <b>Art  <span className="badge text-bg-light rounded-pill">
                                                    {filterArray.length}</span></b></button>
                                            }
                                            {tagfilter !== 'art' &&
                                            <button type="button" className="btn btn-secondary SmallButton me-1"
                                            onClick={() => settagfilter('art')}>
                                                <b>Art</b></button>
                                            }
                                        </>
                                        }
                                        {tagfilter === 'pubs' &&
                                        <button type="button" className="btn btn-secondary SmallButton me-1"
                                        onClick={() => settagfilter('pubs')}>
                                            <b>Bars and pubs  <span className="badge text-bg-light rounded-pill">
                                                {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'pubs' &&
                                        <button type="button" className="btn btn-secondary SmallButton me-1"
                                        onClick={() => settagfilter('pubs')}>
                                            <b>Bars and pubs</b></button>
                                        }
                                        {params.slug !== 'copenhagen' &&
                                        <>
                                            {tagfilter === 'buildings' &&
                                            <button type="button" className="btn btn-secondary SmallButton me-1"
                                            onClick={() => settagfilter('buildings')}>
                                                <b>Buildings  <span className="badge text-bg-light rounded-pill">
                                                    {filterArray.length}</span></b></button>
                                            }
                                            {tagfilter !== 'buildings' &&
                                            <button type="button" className="btn btn-secondary SmallButton me-1"
                                            onClick={() => settagfilter('buildings')}>
                                                <b>Buildings</b></button>
                                            }
                                            {tagfilter === 'museums' &&
                                            <button type="button" className="btn btn-secondary SmallButton"
                                            onClick={() => settagfilter('museums')}>
                                                <b>Museums  <span className="badge text-bg-light rounded-pill">
                                                    {filterArray.length}</span></b></button>
                                            }
                                            {tagfilter !== 'museums' &&
                                            <button type="button" className="btn btn-secondary SmallButton"
                                            onClick={() => settagfilter('museums')}>
                                                <b>Museums</b></button>
                                            }
                                        </>
                                        }
                                        
                                    </div> 

                                </div>
                                }
                                {!isMobile &&
                                <div className="mt-4">
                                    <div className="mx-4 bg-light py-1 ps-2"><b>Click to use filter:</b></div>
                                    <div className="mb-1 mt-1 Text28">
                                        {tagfilter === 'all' &&
                                        <button type="button" className="btn btn-info me-2"
                                        onClick={() => settagfilter('all')}><b>All  <span className="badge text-bg-light rounded-pill">
                                        {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'all' &&
                                        <button type="button" className="btn btn-sm btn-info me-2"
                                        onClick={() => settagfilter('all')}><b>All</b></button>
                                        }
                                        {tagfilter === 'red' &&
                                        <button type="button" className="btn btn-danger me-2"
                                        onClick={() => settagfilter('red')}><b>Must See  <span className="badge text-bg-light rounded-pill">
                                        {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'red' &&
                                        <button type="button" className="btn btn-sm btn-danger me-2"
                                        onClick={() => settagfilter('red')}><b>Must See</b></button>
                                        }
                                        {tagfilter === 'green' &&
                                        <button type="button" className="btn btn-success me-2"
                                        onClick={() => settagfilter('green')}><b>Try Hard to See  <span className="badge text-bg-light rounded-pill">
                                        {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'green' &&
                                        <button type="button" className="btn btn-sm btn-success me-2"
                                        onClick={() => settagfilter('green')}><b>Try Hard to See</b></button>
                                        }
                                        {tagfilter === 'blue' &&
                                        <button type="button" className="btn btn-primary"
                                        onClick={() => settagfilter('blue')}><b>Worthwhile  <span className="badge text-bg-light rounded-pill">
                                        {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'blue' &&
                                        <button type="button" className="btn btn-sm btn-primary"
                                        onClick={() => settagfilter('blue')}><b>Worthwhile</b></button>
                                        }

                                        <hr className="bg-danger border-2 border-secondary mx-auto" style={{ width: '200px' }} />

                                        <div className="mb-1 mt-1">
                                        {params.slug === 'Copenhagen-things-to-do' &&
                                            <>
                                                {tagfilter === 'danish_lunch' &&
                                                <button type="button" className="btn btn-danger me-2"
                                                onClick={() => settagfilter('danish_lunch')}>
                                                    <b>Classic Danish lunch  <span className="badge text-bg-light rounded-pill">
                                                        {filterArray.length}</span></b></button>
                                                }
                                                {tagfilter !== 'danish_lunch' &&
                                                <button type="button" className="btn btn-danger btn-sm me-2"
                                                onClick={() => settagfilter('danish_lunch')}>
                                                    <b>Classic Danish lunch</b></button>
                                                }

                                                {tagfilter === 'restaurant' &&
                                                <button type="button" className="btn btn-secondary me-2"
                                                onClick={() => settagfilter('restaurant')}>
                                                    <b>Restaurants<span className="badge text-bg-light rounded-pill">
                                                        {filterArray.length}</span></b></button>
                                                }
                                                {tagfilter !== 'restaurant' &&
                                                <button type="button" className="btn btn-secondary btn-sm me-2"
                                                onClick={() => settagfilter('restaurant')}>
                                                    <b>Restaurants</b></button>
                                                }
                                            </>
                                        }
                                        {params.slug !== 'copenhagen' &&
                                        <>
                                            {tagfilter === 'district' &&
                                            <button type="button" className="btn btn-secondary me-2"
                                            onClick={() => settagfilter('district')}>
                                                <b>District  <span className="badge text-bg-light rounded-pill">
                                                    {filterArray.length}</span></b></button>
                                            }
                                            {tagfilter !== 'district' &&
                                            <button type="button" className="btn btn-secondary btn-sm me-2"
                                            onClick={() => settagfilter('district')}>
                                                <b>District</b></button>
                                            }
                                        
                                            {tagfilter === 'art' &&
                                            <button type="button" className="btn btn-secondary me-2"
                                            onClick={() => settagfilter('art')}>
                                                <b>Art  <span className="badge text-bg-light rounded-pill">
                                                    {filterArray.length}</span></b></button>
                                            }
                                            {tagfilter !== 'art' &&
                                            <button type="button" className="btn btn-secondary btn-sm me-2"
                                            onClick={() => settagfilter('art')}>
                                                <b>Art</b></button>
                                            }
                                        </>
                                        }
                                        {tagfilter === 'pubs' &&
                                        <button type="button" className="btn btn-secondary me-2"
                                        onClick={() => settagfilter('pubs')}>
                                            <b>Bars and pubs  <span className="badge text-bg-light rounded-pill">
                                                {filterArray.length}</span></b></button>
                                        }
                                        {tagfilter !== 'pubs' &&
                                        <button type="button" className="btn btn-secondary btn-sm me-2"
                                        onClick={() => settagfilter('pubs')}>
                                            <b>Bars and pubs</b></button>
                                        }
                                        {params.slug !== 'copenhagen' &&
                                        <>
                                            {tagfilter === 'buildings' &&
                                            <button type="button" className="btn btn-secondary me-2"
                                            onClick={() => settagfilter('buildings')}>
                                                <b>Buildings  <span className="badge text-bg-light rounded-pill">
                                                    {filterArray.length}</span></b></button>
                                            }
                                            {tagfilter !== 'buildings' &&
                                            <button type="button" className="btn btn-secondary btn-sm me-2"
                                            onClick={() => settagfilter('buildings')}>
                                                <b>Buildings</b></button>
                                            }
                                            {tagfilter === 'museums' &&
                                            <button type="button" className="btn btn-secondary"
                                            onClick={() => settagfilter('museums')}>
                                                <b>Museums  <span className="badge text-bg-light rounded-pill">
                                                    {filterArray.length}</span></b></button>
                                            }
                                            {tagfilter !== 'museums' &&
                                            <button type="button" className="btn btn-secondary btn-sm"
                                            onClick={() => settagfilter('museums')}>
                                                <b>Museums</b></button>
                                            }
                                        </>
                                        }
                                        
                                    </div> 

                                    </div>
                                </div>
                                }

                                {optionDistrict &&
                                <div className="text-center">
                                    <div className="badge bg-warning my-2 Text18">
                                        {optionDistrict.value}</div>
                                </div>
                                }
                            </div>
                        }



                        {tagfilter === 'pubs' && isMobile &&
                        <div>
                            <div className="text-center">
                                <div className="badge bg-warning my-2 Text18">
                                    Bars and pubs</div>
                            </div>
                        
                            <figure className="text-center mt-3 p-2">
                                <blockquote className="blockquote">
                                    <p>There is nothing which has yet been contrived by man, by which so much happiness is produced as by a good tavern or inn.</p>
                                </blockquote>
                                <figcaption className="blockquote-footer mt-2">
                                Dr. Samuel Johnson <cite title="Source Title">21 March 1776</cite>
                                </figcaption>
                            </figure>
                        </div>
                        }
                        {tagfilter === 'danish_lunch' && isMobile &&
                        <div>
                            <div className="text-center">
                                <div className="badge bg-warning my-2 Text18">
                                    Classic Danish Lunch</div>
                            </div>
                        
                            <figure className="text-center mt-3 p-2">
                                <blockquote className="blockquote">
                                    <p>In Denmark aquavit is called snaps or akvavit, and is primarily consumed in December during 
                                        Christmas lunches or around Easter during Easter lunches. It is consumed year round though, mainly for lunches of 
                                        traditional Danish dishes where beer is also always on the table.</p>
                                </blockquote>
                            </figure>
                        </div>
                        }

                        {value.comment1 && value.source1 && quote_right &&
                            <figure className="text-center mt-3 p-2">
                                <blockquote className="blockquote">
                                    <p>{value.comment1}</p>
                                </blockquote>
                                <figcaption className="blockquote-footer mt-2">
                                    {value.source1} <cite title="Source Title">{value.details1}</cite>
                                </figcaption>
                            </figure>
                        }

                        {value.comment1 && !value.source1 && quote_right &&
                            <figure className="Date rounded p-2 mt-3">
                                <ReactMarkdown>
                                        {value.comment1}               
                                </ReactMarkdown>
                            </figure>
                        }

                    </div>
                </div>
                   
            </React.Fragment>
            )
    })

    const AttractionObjects = filterArray.map(value => {

        let DescCharLimit, row_group_start_class, destination_row_class, row_group_start_sub_class

        if (value.attraction_desc_charlimit) {
            DescCharLimit = value.attraction_desc_charlimit
        }
        else {
            DescCharLimit = 1000
        }

        //Grouping logic
        if (!isMobile) {
            row_group_start_class = 'row sortTitle px-2 mt-2 border-start border-end border-top border-4';
            row_group_start_sub_class = 'row sortSubTitle ps-3 pb-2 border-end border-top border-4';
        }
        if (isMobile) {
            row_group_start_class = 'row sortTitleMobile px-2 mt-2 border-start border-end border-top border-4';
            row_group_start_sub_class = 'row sortSubTitleMobile ps-3 pb-2 border-end border-top border-4';
        }

        if (value.group_name && value.group_start) {
            destination_row_class = 'row p-3 border-start border-end border-top border-4';
        }
        
        if (value.group_name && !value.group_end && !value.group_start) {
            destination_row_class = 'row p-2 border border-4';
            // destination_row_class = 'row p-2 border-start border-end border-bottom border-4';
        }

        if (value.group_name && value.group_end) {
            destination_row_class = 'row mb-3 p-2 border-start border-end border-bottom border-4';
        }

        if (!value.group_name){
            destination_row_class = 'row p-3 mb-4 border rounded border-2';
        }

        return (

            <React.Fragment key={value.id}>

                {value.group_name && value.group_start &&
                    <div>
                        <div className={row_group_start_class}>
                            <div className='col'>
                                {value.group_name}

                                    {value.group_picture && !isMobile &&
                                        <div className="float-md-end ms-md-2">
                                                <button type="button" className="btn btn-success btn-sm" data-bs-toggle="modal" 
                                            data-bs-target="#mapModalGroup" onClick={() => {setModalImage(value.group_picture);}}>
                                                <b>Show map</b>
                                            </button>
                                        </div> 
                                    }
                                    {value.group_picture && isMobile && !value.group_name_sub &&
                                        <div className="pb-2">
                                                <button type="button" className="btn btn-success btn-sm" data-bs-toggle="modal" 
                                            data-bs-target="#mapModalGroup" onClick={() => {setModalImage(value.group_picture);}}>
                                                <b>Show map</b>
                                            </button>
                                        </div> 
                                    }

                            </div>
                        </div>
                        {value.group_name_sub && !isMobile &&
                        <div className={row_group_start_sub_class}>
                            <div className='col'>
                                {value.group_name_sub}
                            </div>
                        </div>
                        }
                        {value.group_name_sub && isMobile &&
                        <div className={row_group_start_sub_class}>
                            <div className='col'>
                                {value.group_name_sub}
                                <div className="pt-2">
                                        <button type="button" className="btn btn-success btn-sm" data-bs-toggle="modal" 
                                    data-bs-target="#mapModalGroup" onClick={() => {setModalImage(value.group_picture);}}>
                                        <b>Show map</b>
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                        }

                        <div className="modal fade" id="mapModalGroup" aria-labelledby="mapModalLabel">
                            <div className="modal-dialog modal-xl">
                                <div className="modal-content">

                                    <div className="modal-header">          
                                        <h2 className="modal-title" id="mapModalLabel">Map</h2>
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>

                                    <div className="modal-body">
                                    
                                        <img src={modalImage}
                                            className="img-fluid rounded" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }


                {/* <div className="row mx-1 mb-3 mt-1 border rounded border-2"> */}
                <div className={destination_row_class}>

                    {/* 1 kolonne */}
                    <div className="col-sm-3 py-2">
                        <img src={value.pic_link} className="img-fluid rounded" alt="">
                        </img>

                        {value.pic_link2 &&
                            <img src={value.pic_link2} className="img-fluid rounded mt-2" alt="">
                            </img>
                        }

                        {isMobile &&
                            <div className="Text11 mt-1">{value.pic_name}</div>
                        }
                        {!isMobile &&
                            <div className="text-center Text13 mt-1">{value.pic_name}</div>
                        }

                        {!isMobile && value.highlights &&
                        <div className="mt-2">
                            {value.address &&
                                <div>
                                    <b>Address</b> <br />
                                    <div className="DateTxt rounded p-2">{value.address}</div>
                                </div>
                            }
                        </div>
                        }

                        {!isMobile &&
                        <div className="mt-2">
                            {value.visitors &&
                                <div>
                                    <b>Visitors</b> <br />
                                    <div className="DateTxt rounded p-2">{value.visitors}</div>
                                </div>
                            }
                        </div>
                        }
                        
                        
                    </div>

                    {/* 2 kolonne */}
                    <div className="col-sm-6 pb-3 border-right">
                        
                            <div className="pt-1">
                                {(value.importance === 1) &&
                                <div>
                                    <h4><div className="badge bg-danger">{value.name}</div>
                                    </h4>
                                </div>
                                }
                                {(value.importance === 2) && 
                                    <div>
                                        <h4><div className="badge bg-success">{value.name}</div>
                                        </h4>
                                    </div>
                                }
                                {(value.importance === 3) &&
                                    <div>
                                        <h4><div className="badge bg-primary">{value.name}</div>
                                        </h4>
                                    </div>
                                }
                                {!value.importance &&
                                    <div>
                                        <h4>{value.name}</h4>
                                    </div>
                                }
                                

                            </div>

                            {/* <div className="badge bg-secondary me-2">
                            {value.category}</div> */}
                            <div className="badge bg-secondary me-2">
                            {value.type}</div>

                            {/* lage denne til button - klikke på for filtrering ? */}
                            <div className="badge bg-secondary">
                            {value.district}</div>

                           {/*  {tagfilter === 'district' &&
                                <button type="button" className="btn btn-secondary"
                                onClick={() => settagfilter('museums')}>
                                    <b>Museums ({filterArray.length})</b></button>
                            }
                            {tagfilter !== 'museums' &&
                                <button type="button" className="btn btn-secondary btn-sm"
                                onClick={() => settagfilter('museums')}>
                                    <b>Museums</b></button>
                            } */}
                        

                        
                            {value.attraction_description &&
                            <div className="factText rounded my-2 p-2"> 
                                        <ReadMoreAndLess
                                            charLimit={DescCharLimit}
                                            readMoreText={" Read more ▼"}
                                            readLessText={" Read less ▲"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                        >
                                        {value.attraction_description}
                                    </ReadMoreAndLess> 
                                        {value.wiki &&
                                        <div className="fst-italic text-end p-1">
                                        - <a href={value.wiki}>more info..</a>
                                        </div>
                                        }
                            </div>
                            }

                            {value.quote &&
                                <div className="card bg-light px-2 pt-2 mt-3">
                                    <div className="quote14 mb-3">{value.quote}</div> 
                                    <div className="blockquote-footer"><i>{value.quote_source}</i></div>
                                </div>
                            }

                            {value.comment2 &&
                                <div className="card bg-light px-2 pt-2 mt-3">
                                    <div className="quote14 mb-3">{value.comment2}</div> 
                                    <div className="blockquote-footer"><i>{value.source2}</i></div>
                                </div>
                            }


                            {!value.attraction_description && value.wiki &&
                                <div className="fst-italic text-end p-1">
                                - <a href={value.wiki} target="_blank" rel="noreferrer">more info..</a>
                                </div>
                            }

                    </div>

                    {/* 3 kolonne */}
                    <div className="col-sm-3 py-2">
                        {value.highlights &&
                            <div>
                                <div className="PeopleHeader py-1 ps-2 rounded"><b>Highlights</b></div>
                                <div className="pt-2 border-top Text13">
                                    <ReactMarkdown>
                                        {value.highlights}               
                                    </ReactMarkdown>
                                </div>
                            </div>
                        }
                        {!isMobile && !value.highlights &&
                        <div className="my-3">
                            {value.address &&
                                <div>
                                    <b>Address</b> <br />
                                    <div className="DateTxt rounded p-2">{value.address}</div>
                                </div>
                            }
                        </div>
                        }
                        {isMobile &&
                        <div className="my-3">
                            {value.address &&
                                <div>
                                    <b>Address</b> <br />
                                    <div className="DateTxt rounded p-2">{value.address}</div>
                                </div>
                            }
                        </div>
                        }
                        {value.nearest_tube_station &&
                            <div>
                                <b>Metro / Station</b> <br />
                                <div className="DateTxt rounded py-1 px-2">{value.nearest_tube_station}</div>
                            </div>
                        }
                        {value.google_maps &&
                            <div className="mt-2">
                                <b>Google maps</b> <br />
                                <div className="DateTxt rounded p-2">{}
                                    <a href={value.google_maps} target="_blank" rel="noreferrer">click to show on map..</a>
                                </div>
                            </div>
                        }
                        {value.hours &&
                            <div className="mt-2">
                                <b>Hours</b> <br />
                                <div className="DateTxt rounded py-1 px-2">{value.hours}</div>
                            </div>
                        }
                        {value.features &&
                            <div className="mt-2">
                                <b>Features</b> <br />
                                <div className="Date rounded py-1 px-2">
                                    <ReactMarkdown>
                                        {value.features}               
                                    </ReactMarkdown>
                                 </div>
                            </div>
                        }
                        
                    </div>
                        


                </div>
            </React.Fragment>
        )

    })

    //REFERENCES
    const referenceItems = sortedReferences.map(value => {
        return (
            <React.Fragment key={value.id}>
                {
                        (() => {
                        if (value.book_title) {
                        return  <div className="row px-1 my-1">
                                    <div className="col">
                                        <div>{value.author_txt} ({value.published_year}). 
                                        &nbsp;<a href={value.more_info}>{value.book_title}</a>. {value.publisher}. (book) </div>
                                    </div>
                                </div>
                        } if (!value.date_txt && value.person) {
                        return <div className="row px-1 my-1">
                                <div className="col">
                                    <a href={value.url}>
                                        {value.title}
                                    </a> ({value.person})
                                </div>
                            </div>
                        } if (!value.date_txt && !value.person) {
                            return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        }
                        if (!value.person) {
                            return <div className="row px-1 my-1">
                                        <div className="col">
                                            <a href={value.url}>
                                                {value.title}
                                            </a> ({value.date_txt})
                                        </div>
                                    </div>
                        }
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        {value.person} ({value.date_txt}). <a href={value.url}>
                                            {value.title}
                                        </a>
                                    </div>
                                </div>
                        })()
                    }
            </React.Fragment>
        )
    })

    return (
        <main className="my-1 py-1">
            {attractions.length > 0 && (
                <div className="container">

                 <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.wisdomthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {ListHeading}
                        </div>
                    </div>

                    {!isMobile &&
                    <div className="row">
                        <div className="col pb-1 me-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>
                    }

                    <div className="row">
                        <div className="col">
                             {AttractionObjects} 
                        </div>
                    </div>

                    {(references.length > 0) &&
                        <div>
                                <div className="row mt-4">
                                    <div className="col">
                                            <h4>References</h4>
                                    </div>
                                </div>
                                <div className="row px-1">
                                    <div className="col">
                                        <b>
                                        <a href='https://en.wikipedia.org'>
                                            Wikipedia
                                        </a>
                                        </b>
                                    </div>
                                </div>
                            
                                <div className="row my-2">
                                    <div className="col">
                                            { referenceItems }
                                    </div>
                                </div>
                        </div>   
                    }

                    <div className="me-2" align="Right">
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </div>


                </div>
            )}
        </main>
    )

}

export default TravelAttractions